.WallMainContent{
    background-color:white;
    font-family:Raleway !important; ;
}

.WallHeader{
    color: black;
    text-align: center;
    background: linear-gradient(to left, #808080, #F4F5F7);
}

.WallLink{
    color: white;   
}

.WallImage{
    max-width: 500px;
    display:flex;
    justify-content:center;
}

.PackageRow{
    padding-top:10px;
}


.BlogImage{
    width:100%;
    height:100%;
    max-width:100%;
    object-fit:cover;
}

.bg-gray{
    background-color:#F4F5F7;
    padding: 50px 0;
    margin: 0;
    max-width:100%;
}
.bg-yellow{
    background-color:#FFD300;
    text-align:center;
    color:#524e5d;
    padding: 50px 0;
    margin: 0;
    max-width:100%;
}

.bg-blue{
    background-color:#354A81;
    text-align:center;
    color:white;
    padding: 50px 0;
    margin: 0;
    max-width:100%;
}

.btn-xl {
    padding: 15px 30px;
    background-color:white;
    color:#524e5d;
    --bs-btn-hover-bg:#524e5d;
    --bs-btn-border-color:#524e5d;
    --bs-btn-bg:#524e5d;
    --bs-btn-hover-border-color:#524e5d;
}

.review img{
    margin: 0 0 20px;
}

.review p{
    margin: 0 10px 30px;
}


@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    src: local('Raleway Light'),local('Raleway-Light'),url(https://fonts.gstatic.com/s/raleway/v11/-_Ctzj9b56b8RgXW8FAriS3USBnSvpkopQaUR-2r7iU.ttf) format('truetype')
}
