.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .accordion-button{
    color: #ffffff;
    background-color:#99c1b9
  }
  

  .accordion-button:not(.collapsed){
    color: #99c1b9;
    background-color:#003049
  }

.AccordionParagraph{
  text-align: justify; 
  color:#003049;
}

.ParallaxHeader{
  color:#003049;
  padding-top:2em
}

.ColorBold{
  color:#ce6054
}
  
  .parallax {
    /* The image used */
    background-image: url("../../public/images/header-four-bg.jpg");
   /* background-color:"#DA4167";*/
    /* Set a specific height */
    min-height: 200px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media (max-width: 400px) {
    .MainContent {
        display:block;
        padding-left:1em;
    }   
  }
  @media (min-width: 1500px) {
    .MainContent {
        display:flex;
        padding-left:1em;
    }   
  }
  
  .MainContent {
    padding-top:0%;
    padding-left:5%;
    padding-right:5%;
  }

  .ProfilePic{
    max-height:400px;
    padding-right:2em;
    padding-top:4em; 
  }
  
  @media (min-width: 1500px) {
    .AboutMeTitle {
        text-align:justify;
      }   
  }
  
  @media (max-width: 400px){
    .AboutMeTitle{
      text-align:center;
    }
  }

  .AboutMeTitle{
    color: #003049;
  }