.PricingList{
    list-style:none;
    padding:30px 0;
    text-align:left;
}


.PackageCard{
    padding-top:0 !important;
    margin:0;
    text-align:center
}

.btn-package{
    padding: 15px 30px;
    background-color:white;
    color:#E10008;
    --bs-btn-hover-bg:#E10008;
    --bs-btn-border-color:#E10008;
    --bs-btn-bg:#E10008;
    --bs-btn-hover-border-color:#E10008;
    --bs-btn-active-bg:#E10008;
    --bs-btn-active-border-color:#E10008;
}



.activePackage{
    background-color:#E10008 !important;
    color:white;
}

