.gameImage{
    width:100%;
    max-width: 100%;
}

.gameParagraph{
    padding-top:0.1rem;
}

.card{
    padding-bottom:5%;
    padding-top:5%;
}

.row{
    padding-bottom:2%;
    padding-top:2%;
}

.col-md-5 {
    padding-right: 20px;
}

.btn-dark {
    --bs-btn-color: white;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ce6054;
    --bs-btn-hover-border-color: #ce6054;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-bg: #ce6054;
    --bs-btn-active-border-color: #ce6054;
    --bs-btn-disabled-color: grey;
    --bs-btn-disabled-bg: transparent;
}