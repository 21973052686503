.imgDefault {
    height: 200px;
    max-width: 400px;
    padding-right: 10px;
    padding-left:10px;
}

.btn-outline-primary {
    --bs-btn-color: #ce6054;
    --bs-btn-border-color: #ce6054;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ce6054;
    --bs-btn-hover-border-color: #ce6054;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #003049;
    --bs-btn-active-bg: #ce6054;
    --bs-btn-active-border-color: #ce6054;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ce6054;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ce6054;
    --bs-gradient: none;
}
@media (max-width: 400px) {
    .carouselItemContentDiv {
        display:block;
        
    }
      
}

@media (min-width: 1200px) {
    .carouselItemContentDiv {
        display:flex;
        width: 60%;
    }
}

.carouselItemContentDiv{
    align-items: center;
    margin: auto;
    padding-top: 2em;
    padding-bottom: 4em !important;
}

