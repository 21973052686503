.borderPanel{
    padding:1%;
    border: 5px solid;

}

.borderPanel.default{
    border-color: #99C1B9; 
    background-color:white;
    color:#003049;
}


.borderPanel.dark{
    border-color: #99C1B9; 
    background-color:#003049;
    color:#99C1B9;
}