.projectthumb{
    max-width:400px;
    max-height:100px;
}
.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
}


.timeline-container::before {
    background-color: #ce6054;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}

.card {
    padding:0px;
}

.timeline-card{
    color:white;
    background-color:#003049;
    width: 40rem;
}

.timeline-button{
    background-color: #ce6054;
}
.timeline-button:hover{
    border: 3px solid #FCBF49;
    background-color: #ce6054;
}

.timeline-year{
    z-index: 99;
    color: #ce6054;
}

.timeline-item{
    flex-direction:column;
    flex-wrap:wrap;
    display:flex;
    align-content:flex-start;
    align-items:center;
}


.timeline-item:nth-child(even){
    align-items:center;
    align-content:flex-end;
}

.timeline-item .circle {
    background-color: #fff;
    border: 3px solid #ce6054;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.badge.bg-primary{
    background-color:#FCBF49 !important;
    color:#003049
}


@media only screen and (max-width: 990px) {
    .timeline-item,
    .timeline-item:nth-child(even) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
        align-content:center!important;
    }
    .timeline-card{
        width: 35rem;
    }
    .timeline-item .circle {
        background-color: #ffffff00;
        border: 3px solid #f66d5e00;
    }
        
    .timeline-container::before {
        background-color: #f66d5e00;

    }
}


@media only screen and (max-width: 630px) {
    .timeline-item,
    .timeline-item:nth-child(even) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
        align-content:center!important;
    }
    .timeline-card{
        width: 20rem;
    }
    .timeline-item .circle {
        background-color: #ffffff00;
        border: 3px solid #f66d5e00;
    }
        
    .timeline-container::before {
        background-color: #f66d5e00;

    }
}

