
.baseAnimatedFooter{
    width:100%;
    height:5px;
    background:#003049;
    opacity: 1;
    position:absolute;
}

.shineAnimatedFooter {
    width:100px;
    height:5px;
    background:#FCBF49;
    opacity: 1;
    position:absolute;
    z-index: 2;
    
    animation-name: move;
    animation-duration: 3s;
    animation-timing-function:linear;
    animation-iteration-count:infinite;
    animation-direction: alternate;
}

@keyframes move {
    0%{

    }
    100% {
        transform: translateX(calc(100vw - 120px));
    }
}


.iconShrink{
    transition:0.5s;
}
.iconShrink:hover
{
    transform: scale(0.9);
}

.linkedIn
{ 
  color: #0077b5; 
  padding:0.5em;
}

.itch
{ 
  color: #ce6054; 
  padding:0.5em;
}

.git
{ 
  color: black; 
  padding:0.5em;
}