.PrototypeContent{
    background-color:#003049;
    align-items:center;
    align-content:center;
    align-self:center;
}

html{
    background-color:#003049;

}

.parallaxproto{
    /* The image used */
    background-image: url("../../public/images/dressform.png");
    /* background-color:"#DA4167";*/
    /* Set a specific height */
    min-height: 1000px;
    
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}