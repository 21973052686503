.bg-gray{
    background-color:#F4F5F7;
    color: black;
    text-align: justify;
    line-height: 1.5;
    margin-bottom:0px;
    padding: 2%;
    font-family: Raleway;
}

.wallTitle{
    font-family: Helvetica sans-serif;
    font-weight: 300;
}

.bg-blue{
    background-color:#354A81;
    text-align:center;
    color:white;
    padding: 50px 0;
    margin: 0;
    max-width:100%;
}

.btn-xl {
    padding: 15px 30px;
    background-color:white;
    color:#524e5d;
    --bs-btn-hover-bg:#524e5d;
    --bs-btn-border-color:#524e5d;
    --bs-btn-bg:#524e5d;
    --bs-btn-hover-border-color:#524e5d;
}



@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    src: local('Raleway Light'),local('Raleway-Light'),url(https://fonts.gstatic.com/s/raleway/v11/-_Ctzj9b56b8RgXW8FAriS3USBnSvpkopQaUR-2r7iU.ttf) format('truetype')
}

