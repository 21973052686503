.homeLink
{
  color: 'white';
  font-weight:'bold';
  font-size:'large';
}

.blueLink 
{
  color: '#003049';
}

.redLink
{
  color:'#D62828';
}

.orangeLink
{
  color:'#F77F00';
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(252,191,73, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
.custom-toggler.navbar-toggler {
    border-color:#FCBF49;
    align-self: flex-start!important;
} 
  
.custom-dropdown-toggle .dropdown-toggle{
    color: #FCBF49;
    align-self: flex-start!important;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link 
{
    color:#FCBF49;
}

.dropdown-item:hover{
    background-color:#ce6054;
    color:#ffffff
}
.navBackground 
{
  background-color: '#003049';
}